<template>
  <div>
    <div
      v-if="isActive === 'block !important'"
      class="cartOverlyPopup"
      @click.stop="closePopup"
    ></div>
    <div
      class="shopping-product-popup"
      id="shopping-product-popup"
      :style="{ display: isActive }"
    >
      <div class="w-product-header">
        <span class="success">
          <span class="check-img"><i class="fa fa-check"></i></span>
          {{ $t("added") }}
        </span>
        {{ $t("add_to_cart") }}
      </div>
      <div class="w-product-body fancy-scroll" v-if="this.cartItems.length">
        <table class="w-popup-table">
          <tr v-for="(cartItem, index) of cartItems" :key="cartItem.id">
            <td class="shopping-product-img">
              <div class="product-popup-img">
                <b-link
                  v-if="cartItem.product.sku != 'Kleurstaal'"
                  :to="'/' + cartItem.product.url_key + '/'"
                >
                  <b-img
                    :src="cartItem.product.image.url"
                    :alt="cartItem.product.name.slice(0, 39)"
                    class="wishlist-prd pr-1"
                  />
                </b-link>
                <b-link
                  :to="'/' + backLink[index].items.url_key + '/'"
                  v-else-if="backLink[index] && backLink[index].items.image"
                >
                  <b-img
                    :src="
                      `${
                        backLink[index].items.image.x_small
                          ? backLink[index].items.image.x_small
                          : cartItem.product.image.url
                      }`
                    "
                    :alt="backLink[index].items.image.x_small.slice(0, 39)"
                    class="wishlist-prd pr-1"
                  />
                </b-link>
              </div>
            </td>
            <td class="shopping-product-name">
              <b-link :to="'/' + cartItem.product.url_key + '/'">
                {{ cartItem.product.name }}
              </b-link>
              <div class="qty-sum">
                {{ $t("quantity") }}: {{ cartItem.quantity }}
              </div>
            </td>

            <td class="shopping-product-price">
              {{ formatCurrency(cartItem.prices.price_incl_tax.value) }}
              <span v-if="cartItem.product.unit">
                {{ cartItem.product.unit }}
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="no-data-found">
        {{ $t("no_products_in_cart") }}
      </div>
      <div class="w-product-footer">
        <b-link
          @click.stop="closePopup"
          to="/shopping-cart/"
          class="popup-link bg-primary"
        >
          {{ $t("goto_cart") }}
          <span><font-awesome-icon icon="shopping-cart"/></span>
        </b-link>
        <b-link
          @click.stop="closePopup"
          to="/order/"
          class="popup-link bg-success"
        >
          {{ $t("goto_checkout") }}
          <span> <font-awesome-icon icon="chevron-circle-right"/></span>
        </b-link>
      </div>
    </div>
  </div>
</template>
<script>
import Cart from "@storefront/core/modules/cart/mixins";
export default {
  name: "CartPopup",
  mixins: [Cart],
  data() {
    return {
      isActive: "none",
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    backLink() {
      return this.$store.getters["productSerie/getBackLink"];
    },
  },
  created() {
    this.$root.$on("openCartPopup", this.showPopup);
  },
  methods: {
    showPopup() {
      if (this.isActive == "none") {
        this.isActive = "block !important";
        setTimeout(() => (this.isActive = "none"), 5000);
      } else {
        this.isActive = "none";
      }
    },
    closePopup() {
      this.isActive = "none";
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
  },
};
</script>
