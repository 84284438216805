<template>
  <div id="app" class="ixl">
    <div
      v-if="generalMsg.text != ''"
      class="top-banner"
      :style="
        `background-color:` +
        generalMsg.background_color +
        `; color:` +
        generalMsg.text_color +
        `;`
      "
    >
      <span class="texttop" v-html="generalMsg.text"></span>
    </div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import { isServer } from "@storefront/core/helpers";
import Header from "@/esf_utrecht_bankxl/core/components/header/Header.vue";
import Footer from "@/esf_utrecht_bankxl/core/components/footer/Footer.vue";

import config from "@config";
const contentList = [
  "footer_about_homing_xl",
  "footer_klantenservice",
  "footer_top10_bij_homing_XL",
  "footer_over_homing_xl",
  "footer_populair",
  "footer_laat_je_inspireren",
  "footer_adres",
  "home_trend_block_1",
  "shipping_cost",
  "success_page",
  "contact_top_content",
  "contact_bottom_content",
  "page_not_found",
  "content_page",
  "our_certainties",
  "footer_social_links",
  "header_store_switch",
  "header_top_right_block",
  "login_page_block",
  "header_menu_static",
  "terms_and_conditions",
  "privacy_policy",
  "cart_bottom_content",
  "action_banner_search_page",
  "modal_content_montage",
  "returns_guest",
];
const sliders = [
  "home",
  "informative_text_slider",
  "home_page_category_slider",
  "home_banken_slider",
  "trends_slider_trends",
  "trends_slider_nieuw",
  "lees_meer_search",
  "store_switch",
  "lees_meer_slider_brands",
];

export default {
  name: "App",
  metaInfo() {
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      titleTemplate: "%s | " + config.app_name,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
    generalMsg() {
      return this.$store.state.messages.generalMsg;
    },
  },
  watch: {
    "$store.state.messages.msgItems": async function () {
      const msgs = await this.$store.dispatch("messages/giveLastMessages");
      msgs.forEach(function (msg) {
        this.makeToast(msg);
      }, this);
    },
    "$store.state.user.isLoggedIn": async function (isLoggedIn) {
      if (isLoggedIn == true) {
        this.$store.dispatch("wishlist/loadLoginWishlist");
      } else {
        this.$store.dispatch("wishlist/loadWishlist");
      }
    },
  },
  components: {
    Header,
    Footer,
  },
  async serverPrefetch() {
    await this.$store.dispatch("cmsPage/loadDefault", {
      id: "home",
    });
    await this.$store.dispatch("cmsBlock/multiple", {
      key: "identifier",
      value: contentList,
    });
    await this.$store.dispatch("sliders/multiple", {
      key: "identifier",
      value: sliders,
    });
    await this.$store.dispatch("home/loadHomeBlogs", {
      category_id: "1",
    });

    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("menu/loadMenu");

    await this.$store.dispatch("home/loadKiyohRatings");
    await this.$store.dispatch("home/loadSpecialSelectedProducts");
    await this.$store.dispatch("home/loadLatestTrendsProducts");
    await this.$store.dispatch("home/loadHomeBestSellers");
    await this.$store.dispatch("home/loadHomeNewSofas");

    await this.$store.dispatch("product/loadBrandSlider");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    await this.$store.dispatch("faq/load");
    await this.$store.dispatch("forms/loadForms");
    await this.$store.dispatch("blog_additional/load");
    await this.$store.dispatch("blog_additional/loadCustomerService");
    await this.$store.dispatch("cart/loadPickupLocations");

    /*
    await this.$store.dispatch("messages/loadGeneralMsg");
    await this.$store.dispatch("product/loadBrandSlider");
    await this.$store.dispatch("menu/loadMenu");
    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    await this.$store.dispatch("stores/load");
    await this.$store.dispatch("home/loadBundles");
   

    
    */
  },
  async created() {
    if (!isServer) {
      await this.$store.dispatch("user/loadUser");
      if (!document.getElementById("Cookiebot")) {
        var script = document.createElement("script");
        script.src = "https://consent.cookiebot.com/uc.js";
        script.dataset.cbid = "73c87479-fd79-4fe8-a149-ce0ca9dbf21a";
        script.async = true;
        script.id = "Cookiebot";
        document.head.appendChild(script); //or something of the likes
      }
      this.$gtm.enable(true);
    }
  },
  async beforeMount() {
    if (this.$store.getters["user/getIsLoggedIn"] == true) {
      if (this.$route.name === "login") {
        return this.$router.push({
          name: "account",
        });
      } else if (this.$route.name === "create-account") {
        return this.$router.push({
          name: "account",
        });
      } else if (this.$route.name === "en-login") {
        return this.$router.push({
          name: "en-account",
        });
      } else if (this.$route.name === "en-create-account") {
        return this.$router.push({
          name: "en-account",
        });
      }
    } else {
      if (this.$route.name === "account") {
        return this.$router.push({
          name: "login",
        });
      } else if (this.$route.name === "en-account") {
        return this.$router.push({
          name: "en-login",
        });
      }
    }
    await this.$store.dispatch("user/loadUser");
    if (this.$store.getters["cart/getCartIsLoaded"] == false) {
      await this.$store.dispatch("cart/loadCart");
    }
    if (this.$store.getters["wishlist/getWishlistId"] == null) {
      await this.$store.dispatch("wishlist/loadWishlist");
    }
  },
  methods: {
    handleScroll() {
      const header = document.querySelector(".header");
      const isHeaderHidden =
        header && header.classList.contains("navbar--hidden");

      if (isHeaderHidden) {
        document.documentElement.style.setProperty("--top-spacing", "8px");
        document.documentElement.style.setProperty(
          "--top-spacing-desktop-xs",
          "8px"
        );
        document.documentElement.style.setProperty(
          "--top-spacing-tablets-md",
          "8px"
        );
        document.documentElement.style.setProperty(
          "--top-spacing-phone-xl",
          "8px"
        );
      } else {
        document.documentElement.style.setProperty("--top-spacing", "200px");
        document.documentElement.style.setProperty(
          "--top-spacing-desktop-xs",
          "190px"
        );
        document.documentElement.style.setProperty(
          "--top-spacing-tablets-md",
          "128px"
        );
        document.documentElement.style.setProperty(
          "--top-spacing-phone-xl",
          "100px"
        );
      }
    },

    makeToast(msg) {
      this.$bvToast.toast(msg.text, {
        //title: msg.title,
        variant: msg.type,
        autoHideDelay: 5000,
        toaster: "b-toaster-top-full",
        solid: true,
        append: true,
      });
    },
  },
};
</script>

<style lang="scss">
.b-toaster-slot {
  top: var(--top-spacing) !important;
  transition: top 0.6s ease-in-out;

  @include desktop-xs {
    top: var(--top-spacing-desktop-xs) !important;
  }
  @include tablets-md {
    top: var(--top-spacing-tablets-md) !important;
  }
  @include phone-xl {
    top: var(--top-spacing-phone-xl) !important;
  }
}

.toast-body {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
</style>
